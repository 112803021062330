/*
* ログインなど
*/
@import "base/_variables";

/*
* ログイン画面
*/
.bodyAuth {
  padding: 8% 0;
  header {
    text-align: center;
  }
  /*
  * 入力フォーム
  */
  .sectionLogin {
    margin: 1.6rem auto 8.0rem auto;
    padding: 2.4rem;
    background-color: #f2f2f2;
    @include pc() {
      max-width: 400px;
    }
    article {
      padding: 2.4rem;
      background-color: $color-white;
    }
  }

}
